<template>
  <section v-if="currentUser" class="need-verify">
    <div class="need-verify-container">
      <h1 class="need-verify-title">
        Please verify your email
      </h1>

      <h4 class="need-verify-title">
        We sent an email to {{ currentUser.email }}
      </h4>

      <p>Just click on the link in that email to complete your SignUp</p>
      <p>If you don't see it, you may need to check <strong>check your spam</strong> folder.</p>

      <template v-if="!isConfirmationSended">
        <span>Still can't find the email?</span>
        <AppButton
          class="need-verify-button"
          color="var(--button-primary-color)"
          dark
          @click="resendConfirmationEmail"
        >
          Resend Email
        </AppButton>
      </template>
      <a class="d-block" @click="logout">Logout</a>
      <span class="error-message" @click="logout">{{ error }}</span>
    </div>
  </section>
</template>

<script>
import Fire from '@/plugins/firebase'
import { mapActions } from 'vuex'

export default {
  name: 'VerifyEmailNotice',
  data() {
    return {
      currentUser: null,
      isConfirmationSended: false,
      error: null
    }
  },
  async mounted() {
    this.currentUser = await Fire.getCurrentUser()
    if (!this.currentUser || this.currentUser.emailVerified) {
      this.$router.push({ name: 'Home' })
    }
  },
  methods: {
    ...mapActions(['setUser']),
    async resendConfirmationEmail() {
      try {
        await this.currentUser.sendEmailVerification()
        this.isConfirmationSended = true
        this.error = null
      } catch (err) {
        this.error = err
      }
    },
    async logout() {
      await Fire.auth().signOut()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.need-verify {
  padding: 136px 0;

  @include breakpoint-reverse(small) {
    padding: 0;
  }
}

.need-verify-container {
  margin: 0 auto;
  border-radius: var(--border-radius-default);
  padding: 28px 60px;
  max-width: 460px;
  text-align: center;
  background-color: var(--background-secondary-color);

  @include breakpoint-reverse(small) {
    padding: 28px;
  }
}

.need-verify-title {
  margin-bottom: 12px;
}

.need-verify-button {
  margin: 10px 0;
}

.error-message {
  color: var(--error-color);
}
</style>
